@import './../../variables.scss';
#Login{
    background-color: rgb(238, 238, 238);
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    .TarjetaLogin{
        background-color: white;
        height: 400px;
        width: calc(100% - 20px);
        max-width: 400px;
        border-radius: 3px;
        box-sizing: border-box;
        box-shadow: -3px 3px 6px #00000040, 3px 3px 6px #00000040;
        .Logo {
            display: flex;
            justify-content: center;
            .img {
                width: 150px;
                height: 150px;
                background-color: white;
                margin-top: -75px;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                border-bottom-left-radius: 0px;
                border-bottom-right-radius: 0px;            
                .imgF{
                    background-image: url(/IMG/logoletras.png);
                    width: 130px;
                    height: 130px;
                    border-radius: 50%;
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position: center;
                    border-bottom-left-radius: 0px;
                    border-bottom-right-radius: 0px;
                }
            }
        }
        .LoginContenedor {
            //background-color: antiquewhite;
            height: calc(100% - 75px);
            display: flex;
            align-items: center;
            .ContenedorInput {
                box-sizing: border-box;
                padding: 10px;
                .InputLabel {
                    display: block;
                    user-select: none;
                }
                input{
                    box-sizing: border-box;
                    width: 100%;
                    height: 35px;
                    margin-top: 5px;
                    border: none;
                    border-bottom: 2px solid $color1;
                    transition: 100ms;
                    font-size: 20px;
                    color: rgb(56, 56, 56);
                }
                input:active{
                    border-bottom: 2px solid $color3;
                }
                input:focus{
                    outline: none;
                    border-bottom: 2px solid $color3;
                }
            }
            .ContenedorBtnLogin {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: 25px;
                .LoginBtn {
                    width: 60px;
                    height: 60px;
                    box-sizing: border-box;
                    box-shadow: 2px 2px 6px #00000040,-2px 2px 6px #00000040;
                    border-radius: 50%;
                    background-image: url(/IMG/flechalogin.png);
                    background-size: 45px;
                    background-repeat: no-repeat;
                    background-position: center;
                }
                .LoginBtn:active{
                    background-color: $color2;
                }
                .Enviando{
                    width: 60px;
                    height: 60px;
                    box-sizing: border-box;
                    box-shadow: 2px 2px 6px #00000040,-2px 2px 6px #00000040;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    .Spinner{
                        width: 30px;
                        height: 30px;
                        border: 8px solid $color1;
                        border-top: 8px solid $color3;
                        border-radius: 50%;
                        animation: spin 1s linear infinite;
                    }
                    @keyframes spin {
                        0% { transform: rotate(0deg); }
                        100% { transform: rotate(360deg); }
                    }
                }
            }
        }
    }
}