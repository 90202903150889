$icomoon-font-family: "icomoon" !default;
$icomoon-font-path: "fonts" !default;

$icon-filter_center_focus-black-24dp: "\e91b";
$icon-print-black-24dp: "\e91a";
$icon-cerebro: "\e916";
$icon-hueso: "\e915";
$icon-mulculo: "\e913";
$icon-pulmon: "\e914";
$icon-SetBack: "\e912";
$icon-RaH: "\e911";
$icon-RsH: "\e910";
$icon-Vflip: "\e90f";
$icon-Hflip: "\e90e";
$icon-CobbAngle: "\e90d";
$icon-Angle: "\e90c";
$icon-WEELSCROLL: "\e90b";
$icon-ZoomMouseWheel: "\e90a";
$icon-Length: "\e906";
$icon-pause: "\e900";
$icon-play_arrow: "\e901";
$icon-screen_rotation: "\e909";
$icon-cast: "\e917";
$icon-cast_connected: "\e918";
$icon-keyboard_arrow_down: "\e907";
$icon-camera_alt: "\e919";
$icon-menu: "\e902";
$icon-arrow_back_ios: "\e903";
$icon-arrow_forward_ios: "\e904";
$icon-info: "\e905";
$icon-pan_tool: "\e908";
$icon-search: "\e986";
$icon-contrast: "\e9d5";
$icon-file-pdf: "\eadf";

