@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?vaxcah');
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?vaxcah#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?vaxcah') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?vaxcah') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?vaxcah##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

i, .icomoon-liga {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  
  /* Enable Ligatures ================ */
  letter-spacing: 0;
  -webkit-font-feature-settings: "liga";
  -moz-font-feature-settings: "liga=1";
  -moz-font-feature-settings: "liga";
  -ms-font-feature-settings: "liga" 1;
  font-feature-settings: "liga";
  -webkit-font-variant-ligatures: discretionary-ligatures;
  font-variant-ligatures: discretionary-ligatures;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-filter_center_focus-black-24dp {
  &:before {
    content: $icon-filter_center_focus-black-24dp; 
  }
}
.icon-print-black-24dp {
  &:before {
    content: $icon-print-black-24dp; 
  }
}
.icon-cerebro {
  &:before {
    content: $icon-cerebro; 
  }
}
.icon-hueso {
  &:before {
    content: $icon-hueso; 
  }
}
.icon-mulculo {
  &:before {
    content: $icon-mulculo; 
  }
}
.icon-pulmon {
  &:before {
    content: $icon-pulmon; 
  }
}
.icon-SetBack {
  &:before {
    content: $icon-SetBack; 
  }
}
.icon-RaH {
  &:before {
    content: $icon-RaH; 
  }
}
.icon-RsH {
  &:before {
    content: $icon-RsH; 
  }
}
.icon-Vflip {
  &:before {
    content: $icon-Vflip; 
  }
}
.icon-Hflip {
  &:before {
    content: $icon-Hflip; 
  }
}
.icon-CobbAngle {
  &:before {
    content: $icon-CobbAngle; 
  }
}
.icon-Angle {
  &:before {
    content: $icon-Angle; 
  }
}
.icon-WEELSCROLL {
  &:before {
    content: $icon-WEELSCROLL; 
  }
}
.icon-ZoomMouseWheel {
  &:before {
    content: $icon-ZoomMouseWheel; 
  }
}
.icon-Length {
  &:before {
    content: $icon-Length; 
  }
}
.icon-pause {
  &:before {
    content: $icon-pause; 
  }
}
.icon-play_arrow {
  &:before {
    content: $icon-play_arrow; 
  }
}
.icon-screen_rotation {
  &:before {
    content: $icon-screen_rotation; 
  }
}
.icon-cast {
  &:before {
    content: $icon-cast; 
  }
}
.icon-cast_connected {
  &:before {
    content: $icon-cast_connected; 
  }
}
.icon-keyboard_arrow_down {
  &:before {
    content: $icon-keyboard_arrow_down; 
  }
}
.icon-camera_alt {
  &:before {
    content: $icon-camera_alt; 
  }
}
.icon-menu {
  &:before {
    content: $icon-menu; 
  }
}
.icon-arrow_back_ios {
  &:before {
    content: $icon-arrow_back_ios; 
  }
}
.icon-arrow_forward_ios {
  &:before {
    content: $icon-arrow_forward_ios; 
  }
}
.icon-info {
  &:before {
    content: $icon-info; 
  }
}
.icon-pan_tool {
  &:before {
    content: $icon-pan_tool; 
  }
}
.icon-search {
  &:before {
    content: $icon-search; 
  }
}
.icon-contrast {
  &:before {
    content: $icon-contrast; 
  }
}
.icon-file-pdf {
  &:before {
    content: $icon-file-pdf; 
  }
}

