@import '../../variables.scss';

#Externo{
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    @import './Cargando.scss';
    @import './Caducado.scss';
}